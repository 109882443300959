<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row class="justify-content-end">
            <b-col cols="6">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  :placeholder="$t('Common.Search')"
                  v-model="search"
                />
              </b-input-group>
            </b-col>
            <b-col cols="3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="w-100"
                @click="
                  () => {
                    $router.push('/food/ingredient-main-form/new');
                  }
                "
                v-if="$can('write', 'food')"
              >
                {{ $t("IngredientMainMaster.AddIngredient") }}
              </b-button>
            </b-col>
          </b-row>

          <div class="box mt-2">
            <b-table
              striped
              hover
              responsive
              class="position-relative"
              primary-key="id"
              empty-text="No matching records found"
              show-empty
              :per-page="pagination.perPage"
              :current-page="pagination.currentPage"
              :items="IngredientMainLists"
              :fields="fields"
            >
              <template #head()="data">{{ $t(data.label) }}</template>

              <template #cell(ingredient_is_active)="data">
                <b-form-checkbox
                  name="check-button"
                  switch
                  inline
                  :checked="data.item.ingredient_is_active == 1 ? true : false"
                  @change="activateIngredientMain(data.item)"
                  v-if="$can('write', 'food')"
                />
                <span v-else>
                  <span
                    :class="
                      data.item.ingredient_is_active
                        ? 'badge text-capitalize badge-light-success badge-pill'
                        : 'badge text-capitalize badge-light-danger badge-pill'
                    "
                    >{{
                      data.item.ingredient_is_active ? "Active" : "In-Active"
                    }}</span
                  >
                </span>
              </template>
              <template #cell(edit)="data">
                <feather-icon
                  icon="EditIcon"
                  @click="
                    () => {
                      $router.push(
                        `/food/ingredient-main-form/${data.item.ingredient_id}`
                      );
                    }
                  "
                  v-if="$can('write', 'food')"
                />
                <feather-icon
                  v-else
                  icon="EyeIcon"
                  @click="
                    () => {
                      $router.push(
                        `/food/ingredient-main-form/${data.item.ingredient_id}`
                      );
                    }
                  "
                />
              </template>
            </b-table>
          </div>

          <b-card class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="IngredientMainLists.length"
                :per-page="pagination.perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BInputGroupPrepend,
  BCard,
  BTable,
  BInputGroupAppend,
  BButton,
  BButtonGroup,
  BDropdown,
  BRow,
  BCol,
  BContainer,
  BFormGroup,
  BPagination,
  BInputGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FoodServices from "@/apiServices/FoodServices";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    FeatherIcon,
    BInputGroupPrepend,
    BCard,
    BTable,
    BInputGroupAppend,
    BButton,
    BButtonGroup,
    BDropdown,
    BRow,
    BCol,
    BContainer,
    BFormGroup,
    BPagination,
    BInputGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
  },
  data() {
    return {
      search: "",
      pagination: {
        currentPage: 1,
        perPage: 50,
      },
      fields: [
        {
          key: "ingredient_id",
          label: "Ingredient Master List.INGREDIENTID",
          sortable: true,
        },
        {
          key: "ingredient_name",
          label: "IngredientMainMaster.INGREDIENTNAME",
          sortable: true,
        },
        {
          key: "ingredient_code",
          label: "IngredientMainMaster.INGREDIENTCODE",
          sortable: true,
        },
        {
          key: "ingredient_is_active",
          label: "Common.Status",
        },
        {
          key: "edit",
          label: "Common.Edit",
        },
      ],
      allIngredientMainItemLists: [],
    };
  },
  computed: {
    IngredientMainLists: function () {
      let arr = this.allIngredientMainItemLists;
      let search = this.search;
      if (search) {
        search = search.toLowerCase();
        arr = arr.filter((item, index) => {
          let ingredient_name = item.ingredient_name;
          let ingredient_code = item.ingredient_code;
          ingredient_name = ingredient_name.toLowerCase();
          if (
            (ingredient_name && ingredient_name.includes(search)) ||
            (ingredient_code && ingredient_code.toLowerCase().includes(search))
          ) {
            return true;
          }
          return false;
        });
        this.pagination.currentPage = 1;
      }
      return arr;
    },
  },
  methods: {
    getAllIngredientMainList() {
      FoodServices.getAllIngredientMain()
        .then((response) => {
          if (response.data.status) {
            this.allIngredientMainItemLists = response.data.data;
            // console.log(this.allIngredientMainItemLists);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || "Validation Failed",
                icon: response.data.icon,
                variant: response.data.variant,
              },
            });
          }
        })
        .catch((error) => {
          console.log("Fetching Ingredient Main Master ", error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Server Error",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        });
    },
    activateIngredientMain(item) {
      let paylod = {
        ingredient_id: item.ingredient_id,
        status: item.ingredient_is_active ? false : true,
      };
      FoodServices.setIngredientMainStatus(paylod)
        .then((response) => {
          if (response.data.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Ingredient Main Status is Updated SuccessFully.",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.getAllIngredientMainList();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message || "Validation Failed",
                icon: response.data.icon,
                variant: response.data.variant,
              },
            });
          }
        })
        .catch((error) => {
          console.log("Fetching Ingredient Main Master ", error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Server Error",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        });
    },
  },
  beforeMount() {
    this.getAllIngredientMainList();
  },
};
</script>
